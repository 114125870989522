import { REDUX_CONST } from '../constants'

export const listModels = (state = { data: [] }, action) => {
	switch (action.type) {
		case REDUX_CONST.autodeskConstants.listModels.request:
			return { loading: true }
		case REDUX_CONST.autodeskConstants.listModels.success:
			return { data: action.payload }
		case REDUX_CONST.autodeskConstants.listModels.fail:
			return { error: action.payload }
		case REDUX_CONST.autodeskConstants.listModels.reset:
			return { data: [] }
		default:
			return state
	}
}
export const fetchModel = (state = {}, action) => {
	switch (action.type) {
		case REDUX_CONST.autodeskConstants.fetchModel.request:
			return { loading: true }
		case REDUX_CONST.autodeskConstants.fetchModel.success:
			return { data: action.payload }
		case REDUX_CONST.autodeskConstants.fetchModel.fail:
			return { error: action.payload }
		default:
			return state
	}
}

export const uploadDrawingToHub = (state = {}, action) => {
	switch (action.type) {
		case REDUX_CONST.autodeskConstants.uploadDrawingToHub.request:
			return { loading: true }
		case REDUX_CONST.autodeskConstants.uploadDrawingToHub.success:
			return { data: action.payload }
		case REDUX_CONST.autodeskConstants.uploadDrawingToHub.fail:
			return { error: action.payload }
		case REDUX_CONST.autodeskConstants.uploadDrawingToHub.reset:
			return {}
		default:
			return state
	}
}
export const deleteHubDrawing = (state = {}, action) => {
	switch (action.type) {
		case REDUX_CONST.autodeskConstants.deleteHubDrawing.request:
			return { loading: true }
		case REDUX_CONST.autodeskConstants.deleteHubDrawing.success:
			return { data: action.payload }
		case REDUX_CONST.autodeskConstants.deleteHubDrawing.fail:
			return { error: action.payload }
		case REDUX_CONST.autodeskConstants.deleteHubDrawing.reset:
			return {}
		default:
			return state
	}
}
