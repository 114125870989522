//user-summary.js
import React from 'react'

const UserSummary = () => {
	return (
		<div className='mx-auto pb-8 border-b-2 border-ssiLightBorder1 mb-10 font-bold max-w-6xl'>
			USER SUMMARY
		</div>
	)
}

export default UserSummary
