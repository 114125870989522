//light-control.js
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { GEN_CONST } from '../../../constants/general'
import { useDispatch } from 'react-redux'
import { controlLight } from '../../../redux/actions/IoTActions/lightActions'

const LightControl = () => {
	const dispatch = useDispatch()
	const { data, loading, error } = useSelector((state) => state.controlLight)

	const handleControlLight = (action, device) => {
		console.log('Controlling Light: Turning ', action)
		dispatch(controlLight(action, device))
	}

	return (
		<div className=' p-4 flex flex-col'>
			<h1 className='text-2xl font-bold text-ssiDark4 dark:text-ssiLight4 mb-6'>
				LIGHT CONTROL - PIN 26 on ESP32
			</h1>

			<div className='flex flex-col mx-8 md:mx-0 md:flex-row space-y-4 spacex-0 md:space-x-4 md:space-y-0'>
				<button
					onClick={() => handleControlLight(GEN_CONST.on, 'ESP32-1')}
					disabled={loading}
					className={`px-4 py-2 rounded text-white ${
						loading
							? 'bg-gray-400 cursor-not-allowed'
							: 'bg-green-500 hover:bg-green-600'
					}`}
				>
					TURN ON
				</button>
				<button
					onClick={() => handleControlLight(GEN_CONST.off, 'ESP32-1')}
					disabled={loading}
					className={`px-4 py-2 rounded text-white ${
						loading
							? 'bg-gray-400 cursor-not-allowed'
							: 'bg-red-500 hover:bg-red-600'
					}`}
				>
					TURN OFF
				</button>
			</div>

			<p className='mt-6 text-gray-700'>
				<span className='font-semibold  text-ssiDark4 dark:text-ssiLight4 '>
					CURRENT STATUS:
				</span>{' '}
				<span
					className={`${
						data?.message === GEN_CONST.on ? 'text-green-600' : 'text-red-600'
					}`}
				>
					{data?.message || 'Unknown'}
				</span>
			</p>
			{loading && (
				<p className='text-blue-600 mt-4 animate-pulse'>Loading...</p>
			)}
			{error && <p className='text-red-600 mt-4'>Error: {error}</p>}
		</div>
	)
}

export default LightControl
