//user-list.js
import React from 'react'
import TableItem from '../components/table-item'
import { ICONS_DEFAULT } from '../../../constants/icons'
import {
	ContextMenu,
	CenterModal,
	Button,
	MenuPayload,
	DeleteWarning,
} from '../../../components'
import { useStateContext } from '../../../contexts/ContextProvider'
import { CONST_ADD, CONST_DELETE, CONST_EDIT } from '../../../constants/general'
import { createUser, deleteUser, updateUser } from '../../../redux/actions'
import { useDispatch } from 'react-redux'
import UserAddEdit from './user-add-edit'

const headerFields = ['NAME', 'EMAIL', 'EMPLOYEE', 'ADMIN', ' ']

const UserList = ({ users }) => {
	const { handleClick, isClicked, lastEvent, lastPayload, currentPalette } =
		useStateContext()

	const { color2 } = currentPalette

	const dispatch = useDispatch()

	if (users[0] === 'Error loading users...') {
		return (
			<div className='flex flex-col text-center justify-center'>
				<div>Error Loading Users.</div>
			</div>
		)
	}

	const handler = (e, element) => handleClick('contextMenu', e, element)

	const centerModalActionHandler = (e, user, type) => {
		e.preventDefault()

		if (type === CONST_ADD) {
			dispatch(createUser(user))
		} else if (type === CONST_EDIT) {
			dispatch(updateUser(user))
		}
	}

	const contextMenuActionHandler = (e, payload, user) => {
		e.preventDefault()
		switch (payload) {
			case CONST_ADD:
				handleClick('centerModal', e, {
					element: (
						<UserAddEdit onSave={centerModalActionHandler} type={CONST_ADD} />
					),
					type: CONST_ADD,
				})

				break
			case CONST_EDIT:
				handleClick('centerModal', e, {
					element: (
						<UserAddEdit
							onSave={centerModalActionHandler}
							type={CONST_EDIT}
							user={user}
						/>
					),
					type: CONST_EDIT,
				})

				break
			case CONST_DELETE:
				handleClick('centerModal', e, {
					element: (
						<DeleteWarning
							message={'Are you sure you want to delete this user?'}
							onClick={(e) => {
								e.preventDefault()

								dispatch(deleteUser(user._id))
							}}
						/>
					),
					type: CONST_DELETE,
				})

				break

			default:
				break
		}
	}

	const saveHandler = (e) => {
		e.preventDefault()
	}

	return (
		<div className='grid grid-cols-5 max-w-6xl mx-auto '>
			{isClicked.centerModal && (
				<CenterModal
					injectedButton={
						<Button
							icon={ICONS_DEFAULT.cancel}
							color='rgb(153, 171, 180)'
							bgHoverColor='light-gray'
							size='lg'
							borderRadius='50%'
							noShadow={true}
							padding={'1 m-auto'}
						/>
					}
					width={'w-[400px]'}
					category={'Configuration'}
					title={
						lastPayload.type === CONST_EDIT
							? 'Edit User'
							: lastPayload.type === CONST_ADD
							? 'Add User'
							: 'Delete User'
					}
					buttonColor={color2}
					headerClass='flex justify-between border-b-1 border-ssiLightBorder1 dark:border-ssiDarkBorder1'
					// disabled={false}
					categoryClass='px-2 text-sm text-ssiLightTextInactive dark:text-ssiDarkTextInactive'
					titleClass='px-2 text-md pb-1 font-extrabold tracking-tight dark:text-ssiDarkTextActive text-ssiLightTextActive '
				>
					{lastPayload.element}
				</CenterModal>
			)}
			{isClicked.contextMenu && (
				<ContextMenu
					injectedButton={
						<Button
							icon={ICONS_DEFAULT.cancel}
							color='rgb(153, 171, 180)'
							bgHoverColor='light-gray'
							size='lg'
							borderRadius='50%'
							noShadow={true}
							padding={'1 m-auto'}
						/>
					}
					headerClass='flex justify-between border-b-1 border-ssiLightBorder1 dark:border-ssiDarkBorder1'
					lastEvent={lastEvent}
					onSave={saveHandler}
					width={'w-[200px]'}
					category={'Configuration'}
					title={'Update User'}
					buttonColor={color2}
					// disabled={false}
					categoryClass='text-xs text-ssiLightTextInactive dark:text-ssiDarkTextInactive'
					titleClass='text-sm font-extrabold tracking-tight dark:text-ssiDarkTextActive text-ssiLightTextActive'
					xOffset={-200}
				>
					{lastPayload}
				</ContextMenu>
			)}

			<div className='col-span-2 md:col-span-4 font-bold  mb-2'>USER LIST</div>
			<div className='col-span-3 md:col-span-1 font-bold mx-auto mb-2'>
				<div
					onClick={(e) => contextMenuActionHandler(e, CONST_ADD)}
					className='flex items-center justify-end mx-auto gap-2 hover:scale-110 bg-ssiGold1 dark:bg-ssiGold1 text-ssiDark4  rounded-lg px-4 py-2 font-bold'
				>
					<div className=' text-ssiDark4 '>{ICONS_DEFAULT.plus}</div>
					<div className='text-sm'>CREATE USER</div>
				</div>
			</div>
			{headerFields.map((field, idx) => (
				<div
					key={idx}
					className='flex col-span-1 justify-center items-center text-xs font-bold pl-4'
				>
					{field}
				</div>
			))}
			<div className='col-span-5 max-h-96 overflow-scroll'>
				{users.map((user, idx) => (
					<TableItem
						key={idx}
						className={' col-span-1  pl-4 py-2 overflow-hidden'}
						wrapperClass={
							'flex col-span-5 text-xs items-center justify-center grid grid-cols-5  max-h-[400px] overflow-y-auto border-b-1 border-ssiLightBorder1 dark:border-ssiDarkBorder1'
						}
						textClassname='flex w-full items-center justify-center'
						fields={[
							user.name,
							user.email,
							user.isEmployee ? (
								<div className='text-xl text-green-600'>
									{ICONS_DEFAULT.checkMark}
								</div>
							) : (
								<div className='text-xl text-red-600'>
									{ICONS_DEFAULT.doNotDisturb}
								</div>
							),
							user.isAdmin ? (
								<div className='text-xl text-green-600'>
									{ICONS_DEFAULT.checkMark}
								</div>
							) : (
								<div className='text-xl text-red-600'>
									{ICONS_DEFAULT.doNotDisturb}
								</div>
							),
							<div
								className={
									'flex col-span-1  pl-4 overflow-hidden items-center justify-center'
								}
								onClick={(e) =>
									handler(
										e,
										<MenuPayload
											item={user}
											onAction={contextMenuActionHandler}
										/>,
									)
								}
							>
								<div className='text-lg text-center items-center h-8 w-8 rounded-full p-[6px] font-bold border-1 border-transparent hover:border-ssiLightTextInactive text-ssiLightTextActive dark:border-ssiDarkTextInactive dark:text-ssiDarkTextInactive hover:opacity-50'>
									{ICONS_DEFAULT.moreDots}
								</div>
							</div>,
						]}
					/>
				))}
			</div>
		</div>
	)
}

export default UserList
