//design-views.js
import React, { useEffect, useState } from 'react'
import PageWrapper from '../../components/layout/page-wrapper'
import AutodeskViewer from '../../components/design-views/autodesk-viewer'
import { useDispatch, useSelector } from 'react-redux'
import { fetchModel, listModels } from '../../redux/actions/autodeskActions'

import DrawingList from '../../components/design-views/drawing-list'
import { DesignViewLinks } from '../../components'

const DesignViews = () => {
	const dispatch = useDispatch()
	const modelRdx = useSelector((state) => state.fetchModel)
	const modelsRdx = useSelector((state) => state.listModels)

	const [modelUrn, setModelUrn] = useState(null)
	const [models, setModels] = useState(null)
	const [token, setToken] = useState(null)

	useEffect(() => {
		if (modelRdx?.data?.ready === true) {
			setModelUrn(modelRdx.data.urn)
			setToken(modelRdx.data.viewerToken)
		} else {
			setModelUrn(null)
			setToken(null)
		}
	}, [modelRdx])

	useEffect(() => {
		if (modelsRdx.data) {
			setModels(modelsRdx.data)
		} else setModels(null)
	}, [modelsRdx])

	useEffect(() => {
		dispatch(listModels())
	}, [dispatch])

	const viewModelHandler = (modelUrn) => {
		dispatch(fetchModel({ modelUrn }))
	}

	return (
		<PageWrapper
			sidebarTitle='DESIGN VIEW LINKS'
			sidebarLinks={<DesignViewLinks />}
		>
			<div className='mt-4 mb-8'>
				<div className='font-montserrat font-semibold text-xl  text-center pb-4'>
					SHARED DRAWINGS
				</div>
				<div className='p-4 w-full h-[300px] overflow-scroll bg-ssiLight3 dark:bg-ssiDark4'>
					<DrawingList onViewModel={viewModelHandler} models={models} />
				</div>
			</div>

			<div className='font-montserrat font-semibold text-xl  text-center'>
				VIEWER
			</div>
			{/* @todo need to have a loading state for transloading.  socket.io with polling on the back.
			maybe have the search done by the item not the list? */}
			{modelUrn && token ? (
				<AutodeskViewer urn={modelUrn} token={token} />
			) : (
				<p className=' w-full min-h-[400px] bg-ssiLight3 dark:bg-ssiDark4 mx-auto text-center mt-4 pt-4 '>
					NOTHING TO LOAD
				</p>
			)}
		</PageWrapper>
	)
}

export default DesignViews
