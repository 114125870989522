import React, { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { Header2, LandingPageLinks, Loader, Message } from '../../components'
import { login } from '../../redux/actions/userActions'

// import { CSS_THEME_ROUNDING } from '../../../css/constants'
import { useStateContext } from '../../contexts/ContextProvider'
import { Link } from 'react-router-dom'
import { CONST_DARK } from '../../constants/general'

const LoginScreen2 = (props) => {
	const { category, userName, onSubmit } = props
	//const [user, setUser] = useState(userName ? userName : '')

	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')

	const { currentMode: mode } = useStateContext()

	const dispatch = useDispatch()

	const userLogin = useSelector((state) => state.userLogin)
	const { loading, error, userInfo } = userLogin

	// const redirect = location.search ? location.search.split('=')[1] : HOME_ROUTE

	// useEffect(() => {
	//     if (userInfo) {
	//       history.push(redirect)
	//     }
	//   }, [history, userInfo, redirect])

	const changeEmailHandler = (e) => setEmail(e.target.value)
	const changePasswordHandler = (e) => setPassword(e.target.value)
	// const closeHandler = () => console.log('close')

	const submitHandler = (e) => {
		e.preventDefault()
		if (onSubmit) {
			// onSubmit({ user, password, adminLevel: weighManagerAdminLevel })
			console.log('onSubmit Instead of Login Route')
		} else {
			// dispatch(login({ user, password, adminLevel: weighManagerAdminLevel }))
			dispatch(login(email, password))
		}
	}

	return (
		<>
			<Header2 links={<LandingPageLinks />} />

			<div className='flex items-center justify-center dark:bg-ssiDark1 min-h-screen'>
				{/* <!-- Card Container --> */}
				<div className='relative flex flex-col m-6 space-y-10 bg-ssiLight4 dark:bg-ssiDark2 dark:border-0 shadow-2xl md:overflow-hidden rounded-2xl md:flex-row md:space-y-0 md:m-0'>
					{/* <!-- Left Side --> */}
					<div className='p-6 md:p-20'>
						{/* <!-- Top Content --> */}
						<h2 className='font-mono mb-5 text-xl md:text-4xl font-bold text-ssiLightTextActive dark:text-ssiDarkTextActive'>
							Log In
						</h2>
						<p className='max-w-sm mb-12 font-sans font-light text-ssiLightTextInactive dark:text-ssiDarkTextInactive'>
							Log in to your account to view company assets and drawings.
						</p>
						<form onSubmit={submitHandler}>
							<input
								type='email'
								name='email'
								value={email}
								required
								onChange={changeEmailHandler}
								placeholder='Enter your email address'
								className='w-full my-2 py-3 px-4 border border-ssiLightBorder1 bg-ssiLight4  outline-none   dark:text-ssiDarkTextActive dark:border-ssiDarkBorder1 dark:bg-ssiDark4 rounded-md placeholder:font-sans placeholder:font-light'
							/>
							<input
								type='password'
								name='password'
								value={password}
								required
								onChange={changePasswordHandler}
								className='w-full my-2 py-3 px-4 border border-ssiLightBorder1 bg-ssiLight4  outline-none   dark:text-ssiDarkTextActive dark:border-ssiDarkBorder1 dark:bg-ssiDark4 rounded-md placeholder:font-sans placeholder:font-light'
								placeholder='Enter your password'
							/>

							{/* <!-- Middle Content --> */}
							<div className='flex flex-col items-center justify-between mt-6 space-y-6 md:flex-row md:space-y-0'>
								<div className='font-thin text-ssiLightTextInactive dark:text-ssiDarkTextInactive'>
									Forgot password
								</div>

								<button className='w-full md:w-auto flex justify-center items-center py-4 space-x-4 font-sans font-bold dark:bg-inherit bg-ssiGold1  rounded-md shadow-lg px-9  shadow-ssiLight4 dark:shadow-ssiDark4 hover:bg-opacity-90  hover:shadow-lg border dark:border-ssiDarkTextInactive outline-none transition hover:-translate-y-0.5 duration-150'>
									<span className='text-black dark:text-ssiGold1'>Next</span>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										className='w-7'
										viewBox='0 0 24 24'
										strokeWidth='1.5'
										stroke={mode === CONST_DARK ? '#fabc0f' : '#000'}
										fill='none'
										strokeLinecap='round'
										strokeLinejoin='round'
									>
										<path stroke='none' d='M0 0h24v24H0z' fill='none' />
										<line x1='5' y1='12' x2='19' y2='12' />
										<line x1='13' y1='18' x2='19' y2='12' />
										<line x1='13' y1='6' x2='19' y2='12' />
									</svg>
								</button>
							</div>
						</form>

						{/* <!-- Border --> */}
						<div className='mt-12 border-b border-b-ssiLightBorder1 bg-ssiLight4  dark:border-b-ssiDarkBorder1'></div>
					</div>

					{/* <!-- Right Side --> */}
					<img
						src='images/loginpicture.jpg'
						alt=''
						className='w-[430px] hidden md:block'
					/>

					{/* <!-- Close Button --> */}
					<Link to={'/'}>
						<div className='group absolute -top-5 right-4 flex items-center justify-center w-10 h-10 bg-ssiLight1 dark:bg-ssiDark3 border-1 border-ssiLightBorder1 dark:border-ssiDarkBorder1 dark:hover:border-ssiDarkTextInactive rounded-full  md:top-4 hover:cursor-pointer hover:-translate-y-0.5 transition duration-150'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								className='w-6 h-6 text-ssiDarkTextInactive dark:text-ssiDarkTextInactive group-hover:text-ssiLightTextActive dark:group-hover:text-ssiDarkTextActive'
								viewBox='0 0 24 24'
								strokeWidth='1.5'
								stroke='currentColor'
								fill='none'
								strokeLinecap='round'
								strokeLinejoin='round'
							>
								<path stroke='none' d='M0 0h24v24H0z' fill='none' />
								<line x1='18' y1='6' x2='6' y2='18' />
								<line x1='6' y1='6' x2='18' y2='18' />
							</svg>
						</div>
					</Link>
				</div>
			</div>
			{error && (
				<div className='w-2/4 m-auto'>
					<Message variant='danger' title='Error'>
						{JSON.stringify(error)}
					</Message>
				</div>
			)}
		</>
	)
}

export default LoginScreen2
