//drawing-list.js
import React from 'react'
import DrawingItem from './drawing-item'

const DrawingList = ({ models, onViewModel, onDeleteModel }) => {
	if (models?.length > 0) {
		return (
			<div className='grid grid-cols-3 gap-4'>
				{models.map((model) => (
					<DrawingItem
						key={model.objectName}
						model={model}
						onViewModel={onViewModel}
						onDeleteModel={onDeleteModel}
					/>
				))}
			</div>
		)
	}
	return <p className='w-full mx-auto text-center'>NO MODELS</p>
}

export default DrawingList
