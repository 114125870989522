// phases.js
import React, { useEffect, useState } from 'react'
import Sidebar from '../../../components/Navigation/sidebar2'
import { AdminLinks, Header2, HomePageLinks } from '../../../components'
import { useDispatch, useSelector } from 'react-redux'
import {
	deleteHubDrawing,
	listModels,
	resetItems,
	uploadDrawingToHub,
} from '../../../redux/actions/autodeskActions'
import UploadDrawing from '../../../components/Autodesk/upload-drawing'
import DrawingList from '../../../components/design-views/drawing-list'

const Drawings = () => {
	const dispatch = useDispatch()
	const modelsRdx = useSelector((state) => state.listModels)
	const deleteModelRdx = useSelector((state) => state.deleteHubDrawing)
	const uploadModelRdx = useSelector((state) => state.uploadDrawingToHub)
	const [models, setModels] = useState(null)

	useEffect(() => {
		if (modelsRdx.data) {
			setModels(modelsRdx.data)
		} else setModels(null)
	}, [modelsRdx])

	useEffect(() => {
		if (deleteModelRdx?.data) {
			dispatch(resetItems('delete'))
			dispatch(listModels())
		}
	}, [deleteModelRdx?.data])
	useEffect(() => {
		if (uploadModelRdx?.data) {
			dispatch(resetItems('create'))
			dispatch(listModels())
		}
	}, [uploadModelRdx?.data])

	useEffect(() => {
		dispatch(listModels())
	}, [dispatch])

	const uploadDrawingHandler = (file) => dispatch(uploadDrawingToHub(file))
	const viewModelHandler = (modelUrn) => {
		console.log(
			modelUrn,
			'@todo Futuer addition, do you want a window or the same viewer just in admin mode',
		)
	}

	const deleteDrawingHandler = (bucketKey, objectName) =>
		dispatch(deleteHubDrawing(bucketKey, objectName))
	return (
		<div className='dark:bg-main-dark-bg bg-main-bg dark:text-ssiLight1  mt-20'>
			<Sidebar title={'ADMIN SETTINGS'} links={<AdminLinks />} />
			<Header2 links={<HomePageLinks />} />
			<div className='max-w-6xl mx-auto'>
				<div className='max-w-96  mx-auto'>
					<div className='font-montserrat dark:text-white font-semibold text-xl  text-center justify-center items-center p-4'>
						UPLOAD DRAWING
					</div>
					<UploadDrawing onUploadDrawing={uploadDrawingHandler} />
				</div>
				<div className='mt-8 mb-8'>
					<div className='font-montserrat font-semibold text-xl  text-center pb-4'>
						MANAGE SHARED DRAWINGS
					</div>
					<div className='p-4 w-full h-[800px] overflow-scroll bg-ssiLight3 dark:bg-ssiDark4'>
						<DrawingList
							onViewModel={viewModelHandler}
							models={models}
							onDeleteModel={deleteDrawingHandler}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Drawings
