//sidebar2.js
import React, { useState } from 'react'
import { ICONS_DEFAULT } from '../../constants/icons'
import { CONST_DARK } from '../../constants/general'
import { useStateContext } from '../../contexts/ContextProvider'

const Sidebar = ({ links, title, openOnDefault = false }) => {
	const [isSidebarOpen, setIsSidebarOpen] = useState(openOnDefault)
	const { currentMode: mode } = useStateContext()

	const toggleSidebarHandler = () => setIsSidebarOpen(!isSidebarOpen)
	const sidebarClass = isSidebarOpen ? ' translate-x-0 ' : ' -translate-x-full '
	const sideBorderClass = isSidebarOpen
		? 'left-72 w-2 bg-ssiLight2 dark:bg-ssiDark3 border-ssiLight4 dark:border-ssiDarkBorder1 '
		: 'left-0  w-3 bg-ssiGold1 dark:bg-ssiGold1 border-ssiLight4 dark:border-ssiDark4'
	const sideButtonClass = isSidebarOpen
		? ' left-[272px] bg-ssiLight2 dark:bg-ssiDark3 text-ssiLight4 dark:text-ssiDarkBorder1 '
		: ' -left-2   bg-ssiGold1 dark:bg-ssiGold1 text-ssiLight1 dark:text-ssiDark4   '

	return (
		<div className='flex   '>
			<div
				className={`fixed top-0 left-0 h-full w-72 bg-ssiLight3 dark:bg-ssiDark3 transition-all transform ${sidebarClass}  z-50 `}
			>
				{mode === CONST_DARK ? (
					<img
						src='/images/footer-logo.png'
						alt=''
						className=' w-32 mx-auto mt-10'
					/>
				) : (
					<img src='/images/SSI.png' alt='' className=' w-32 mx-auto mt-10' />
				)}

				<h2 className='mt-5 text-center font-bold text-xl mb-4'>{title}</h2>
				<div className='flex flex-col space-y-3 justify-center'>{links}</div>
			</div>
			<div className='group '>
				<div
					onClick={toggleSidebarHandler}
					className={`${sideBorderClass}  fixed top-0 h-full  border-r-1  dark:group-hover:border-ssiDarkTextActive hover:cursor-pointer group-hover:border-ssiLightTextInactive group-hover:-translate-y-0.5  duration-150 transition-all z-50 `}
				></div>
				<div
					onClick={toggleSidebarHandler}
					className={`${sideButtonClass}  fixed top-32  flex items-center justify-center   h-8 w-8  border-1 border-ssiLight4 dark:border-ssiDarkBorder1 dark:group-hover:border-ssiDarkTextActive dark:group-hover:text-ssiDarkTextActive group-hover:border-ssiLightTextInactive group-hover:text-ssiLightTextInactive rounded-full  hover:cursor-pointer group-hover:-translate-y-0.5  duration-150 transition-all z-50`}
				>
					{isSidebarOpen
						? ICONS_DEFAULT.chevronLeft
						: ICONS_DEFAULT.chevronRight}
				</div>
			</div>
		</div>
	)
}
export default Sidebar
