import axios from 'axios'
import { REDUX_CONST } from '../../constants'
import { API_CONST } from '../../../constants/api'

export const controlLight = (action, device) => async (dispatch, getState) => {
	try {
		dispatch({
			type: REDUX_CONST.iotConstants.controlLight.request,
		})
		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'Content-Type': 'application/json',
			},
		}

		const { data } = await axios.get(
			`${API_CONST.channels.iot}${API_CONST.routes.controlLight}/${action}?device=${device}`,
			config,
		)

		console.log(data)

		dispatch({
			type: REDUX_CONST.iotConstants.controlLight.success,
			payload: data,
		})
	} catch (error) {
		dispatch({
			type: REDUX_CONST.iotConstants.controlLight.fail,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		})
	}
}
