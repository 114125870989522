//drawing-item.js
import React from 'react'
import { ICONS_DEFAULT } from '../../constants/icons'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

const DrawingItem = ({ model, onViewModel, onDeleteModel }) => {
	const location = useLocation() // Get the current location
	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	// Determine if the delete icon should be shown
	const showDelete = userInfo.isAdmin && location.pathname === '/admin/drawings'
	const showLink = location.pathname === '/design-views' || '/'

	return (
		<div className='relative flex dark:border-1 dark:border-gray-700 border-1 border-ssiLight4 bg-ssiLight1 dark:bg-ssiDark2 rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300'>
			{showDelete && (
				<div
					onClick={() => onDeleteModel(model.bucketKey, model.objectName)}
					className='absolute top-0 right-0 p-2 cursor-pointer'
				>
					{/* Render the delete icon */}
					{ICONS_DEFAULT.deleteTrash}
				</div>
			)}
			<img
				src='/images/SSI.png'
				alt='Drawing Preview'
				className='p-2 w-28 h-28 object-cover'
			/>
			<div className='flex flex-col justify-between space-y-2 p-2 text-xs flex-grow'>
				<div className='font-bold'>{model.objectName}</div>
				<div className='text-xs'>
					<p className='text-ssiDark4 dark:text-gray-400'>
						Uploaded on 12/12/2024
					</p>
					{/* View Model Button */}
					{showLink && (
						<div
							onClick={() => onViewModel(model.urn)}
							className='text-blue-500 dark:text-ssiGold1 cursor-pointer hover:underline'
						>
							View Model
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

export default DrawingItem
