//page-wrapper.js
import React from 'react'
import { Header2, HomePageLinks, Sidebar } from '..'

const PageWrapper = ({
	children,
	sidebarTitle,
	sidebarLinks,
	openOnDefault = false,
}) => {
	return (
		<div className='dark:bg-main-dark-bg  bg-main-bg dark:text-white font-opensans mt-20'>
			<Sidebar
				title={sidebarTitle}
				links={sidebarLinks}
				openOnDefault={openOnDefault}
			/>
			<Header2 links={<HomePageLinks />} />
			<div className='max-w-6xl mx-auto'>{children}</div>
		</div>
	)
}

export default PageWrapper
