import React, { useEffect, useRef } from 'react'

const AutodeskViewer = ({ urn, token }) => {
	const viewerDiv = useRef(null) // Reference to the div where the viewer will be embedded

	// const prepareDataForBackend = (viewer) => {
	// 	console.log('prepare for backend')
	// 	const instanceTree = viewer.model.getData().instanceTree
	// 	let bomData = []

	// 	instanceTree.enumNodeChildren(instanceTree.getRootId(), (childId) => {
	// 		viewer.getProperties(
	// 			childId,
	// 			(props) => {
	// 				let partData = {
	// 					dbId: childId,
	// 					name: props.name || '',
	// 					type:
	// 						props.properties.find((p) => p.displayName === 'Type')
	// 							?.displayValue || '',
	// 					material:
	// 						props.properties.find((p) => p.displayName === 'Material')
	// 							?.displayValue || '',
	// 					quantity: 1, // Default quantity for parts
	// 				}
	// 				bomData.push(partData)
	// 			},
	// 			true,
	// 		)
	// 	})

	// 	console.log(bomData)
	// }

	useEffect(() => {
		async function initializeViewer() {
			if (!window.Autodesk) {
				console.error('Autodesk Viewer script not loaded')
				return
			}

			// Clean up any existing viewer instances properly before reinitializing
			if (window.viewer) {
				window.viewer.tearDown()
				window.viewer.finish()
				window.viewer = null
			}

			const options = {
				env: 'AutodeskProduction',
				accessToken: token,
			}

			window.Autodesk.Viewing.Initializer(options, () => {
				window.viewer = new window.Autodesk.Viewing.GuiViewer3D(
					viewerDiv.current,
				)
				window.viewer.start()

				const documentId = `urn:${urn}`
				window.Autodesk.Viewing.Document.load(
					documentId,
					(doc) => {
						const defaultModel = doc.getRoot().getDefaultGeometry()
						window.viewer.loadDocumentNode(doc, defaultModel)
					},
					(error) => {
						console.error('Error loading document:', error)
					},
				)
			})
		}

		if (urn && token) {
			initializeViewer()
		}

		// Cleanup function to handle component unmount and cleanup
		return () => {
			if (window.viewer) {
				window.viewer.tearDown() // Ensures all memory and resources are released
				window.viewer.finish() // Stops and cleans the viewer
				window.viewer = null // Remove reference to prevent memory leaks
			}
		}
	}, [urn, token]) // Dependencies array ensures effect runs only when urn or token changes

	return (
		// Set the viewer container's dimensions using TailwindCSS utilities
		<div className='relative mt-4 mb-8' style={{ minHeight: '600px' }}>
			<div ref={viewerDiv} className='absolute inset-0 w-full h-full'></div>
		</div>
	)
}

export default AutodeskViewer
