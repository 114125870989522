// upload-drawing.js
import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

const UploadDrawing = ({ onUploadDrawing }) => {
	const onDrop = useCallback(
		(acceptedFiles) => {
			// Assuming you want the first file if multiple are dropped
			const file = acceptedFiles[acceptedFiles.length - 1]
			onUploadDrawing(file)
		},
		[onUploadDrawing],
	)

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

	return (
		<div
			{...getRootProps()}
			className='upload-drawing-container bg-ssiLight3 dark:bg-ssiDark4'
			style={{
				border: '2px dashed #ccc',
				padding: '40px',
				textAlign: 'center',
				height: '200px',
			}}
		>
			<input {...getInputProps()} />
			{isDragActive ? (
				<div className='font-display text-sm md:text-lg flex flex-col space-y-3 items-center justify-center'>
					<div>DROP THE FILE HERE</div>
				</div>
			) : (
				<div className='font-display text-sm md:text-lg flex flex-col space-y-3 items-center justify-center'>
					<div>DRAG 'N' DROP A DRAWING HERE</div>
					<div>OR</div>
					<div>CLICK TO SELECT FILE</div>
				</div>
			)}
		</div>
	)
}

export default UploadDrawing
