//Header2.js
import React from 'react'
import { useStateContext } from '../../../contexts/ContextProvider'
import { CONST_DARK } from '../../../constants/general'

const Header2 = ({ links }) => {
	const { currentMode: mode } = useStateContext()
	return (
		<header className=' mx-auto mt-10 px-6  dark:text-ssiLight2 text-center h-40 md:h-20'>
			{/* <!-- Dynamic Logo --> */}
			{/* <div className='bg-logo-light-mode dark:bg-logo-dark-mode bg-no-repeat h-20 w-48 mx-auto md:mx-0 md:absolute top-10 left-10'></div> */}

			{mode === CONST_DARK ? (
				<img
					src='/images/footer-logo.png'
					alt=''
					className='hidden md:block w-32 mx-auto md:mx-0 md:absolute top-5 left-10'
				/>
			) : (
				<img
					src='/images/SSI.png'
					alt=''
					className='hidden md:block w-32 mx-auto md:mx-0 md:absolute top-5 left-10'
				/>
			)}

			{/* <!-- Menu --> */}
			<div className='flex items-center flex-wrap justify-end space-x-4 md:space-x-10 md:absolute top-12 left-52 right-10'>
				{links && links}
			</div>
		</header>
	)
}

export default Header2
