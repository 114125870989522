import React, { useEffect } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Footer, ThemeSettings } from './components'
import {
	DesignViews,
	Login,
	AdminUsers,
	AdminManageDrawings,
	AdminHomepage2,
	IoTPage,
} from './pages/'
import { CONST_LOCAL_STORAGE, GREEN_PALETTE, ROUTES_PAGES } from './constants/'
import './App.css'
import { useStateContext } from './contexts/ContextProvider'
import { CONST_LIGHT } from './constants/general'

const App = () => {
	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	const {
		setCurrentPalette,
		setCurrentMode,
		currentMode,
		activeMenu,
		themeSettings,
	} = useStateContext()

	useEffect(() => {
		const currentThemePalette = localStorage.getItem('colorPalette')
		const currentThemeMode = localStorage.getItem(CONST_LOCAL_STORAGE.themeMode)

		if (currentThemeMode && currentThemeMode !== 'undefined') {
			setCurrentMode(currentThemeMode)
		} else {
			setCurrentMode(CONST_LIGHT)
		}

		if (currentThemePalette && currentThemePalette !== 'undefined') {
			setCurrentPalette(JSON.parse(currentThemePalette))
		} else {
			setCurrentPalette(GREEN_PALETTE)
		}
	}, [setCurrentMode, setCurrentPalette])

	let routes = []

	if (userInfo) {
		routes = (
			<>
				<div
					className={'dark:bg-main-dark-bg  bg-main-bg min-h-screen w-full  '}
				>
					<div>
						{themeSettings && <ThemeSettings />}

						<Routes>
							{/* EXECUTIVE --> POINTS TO PULSE REPORT */}

							{/* {userInfo.isExecutive && (
								<Route
									path={ROUTES_PAGESdesignViews}
									element={<DesignViews />}
								/>
							)} */}

							{/* DRAWINGS */}

							<Route
								path={`${ROUTES_PAGES.designViews}`}
								element={<DesignViews />}
							/>

							{/* ADMIN SECTION */}

							<Route
								path={`${ROUTES_PAGES.admin}${ROUTES_PAGES.users}`}
								element={<AdminUsers />}
							/>
							<Route
								path={`${ROUTES_PAGES.admin}${ROUTES_PAGES.drawings}`}
								element={<AdminManageDrawings />}
							/>
							<Route
								path={`${ROUTES_PAGES.admin}${ROUTES_PAGES.iot}`}
								element={<IoTPage />}
							/>

							{/* GENERAL ROUTES */}

							<Route path={ROUTES_PAGES.admin} element={<AdminHomepage2 />} />

							<Route path={ROUTES_PAGES.home} element={<DesignViews />} />
							<Route path={ROUTES_PAGES.login} element={<DesignViews />} />
							<Route
								path='*'
								element={<Navigate to={ROUTES_PAGES.home} replace />}
							/>
						</Routes>
					</div>
					<Footer />
				</div>
			</>
		)
	} else {
		routes = (
			<>
				{/*  ------------------------------  SHOWN WITH USER/ROLE ACCESS ---------------------   */}
				<div
					className={
						activeMenu
							? 'font-body dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-72 w-full  '
							: 'font-body bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
					}
				>
					<div>
						<Routes>
							<Route path={ROUTES_PAGES.login} element={<Login />} />
							<Route path={ROUTES_PAGES.home} element={<Login />} />
							<Route
								path='*'
								element={<Navigate to={ROUTES_PAGES.home} replace />}
							/>
						</Routes>
					</div>
					<Footer />
				</div>
			</>
		)
	}

	return (
		<div className={currentMode === 'Dark' ? 'dark' : ''}>
			<div className='select-none flex relative dark:bg-main-dark-bg font-body'>
				{routes}
			</div>
		</div>
	)
}

export default App
