import React, { useEffect } from 'react'
import Loader from './Loader'
import { useDispatch, useSelector } from 'react-redux'
import { useStateContext } from '../../contexts/ContextProvider'

import {
	getUsers,
	resetUserItem,
} from '../../redux/actions/userActions/userActions'

const DeleteWarning = ({
	message,
	onClick,
	warningClassname,
	buttonClassname,
}) => {
	const dispatch = useDispatch()
	const deleteUserRdx = useSelector((state) => state.deleteUser)
	const { loading: delete3Loading, data: delete3Success } = deleteUserRdx

	const { handleClick, initialState } = useStateContext()

	useEffect(() => {
		if (delete3Success) {
			handleClick({ ...initialState })
			delete3Success && dispatch(resetUserItem('deleteUser'))
			setTimeout(() => {
				dispatch(getUsers())
			}, 0)
		}

		return () => {}
	}, [delete3Success])
	return (
		<>
			<div
				className={
					warningClassname
						? warningClassname
						: 'mt-2 text-ssiGold1 font-bold dark:text-ssiGold1 p-4 text-md w-full mx-auto'
				}
			>
				{message}
			</div>
			<div className='flex justify-center'>
				<button
					onClick={onClick}
					className={
						buttonClassname
							? buttonClassname
							: ' mt-2 text-ssiGold1 dark:text-ssiGold1 border-1  border-ssiGold1 dark:border-ssiGold1 rounded-theme-rounding font-bold text-md w-2/4 py-1 text-center mx-auto'
					}
				>
					{delete3Loading ? <Loader /> : 'Delete'}
				</button>
			</div>
		</>
	)
}

export default DeleteWarning
