//admin-homepage.js
import React from 'react'
import { AdminLinks, PageWrapper } from '../../components'

const AdminHomepage = () => {
	return (
		<PageWrapper
			sidebarTitle='ADMIN SETTINGS'
			sidebarLinks={<AdminLinks />}
			openOnDefault
		>
			<div className='w-full text-center text-xl font-bold'>
				NOTHING HERE YET, USE SIDEBAR OPTIONS.
			</div>
		</PageWrapper>
	)
}

export default AdminHomepage
