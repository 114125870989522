//admin-links.js
import React from 'react'
import { Link } from 'react-router-dom'
import { ROUTES_PAGES } from '../../../constants'
//@todo map these links in the component that sends them to this
//bring all links into this
const AdminLinks = () => {
	return (
		<React.Fragment>
			<Link
				to={`${ROUTES_PAGES.admin + ROUTES_PAGES.users}`}
				className={
					'mx-4 text-center rounded-theme-rounding bg-ssiGold1 dark:bg-ssiGold1 py-2 px-4 text-ssiDark2 font-semibold  dark:text-ssiDark4 hover:opacity-80 hover:-translate-y-0.5'
				}
			>
				USERS
			</Link>

			<Link
				to={`${ROUTES_PAGES.admin + ROUTES_PAGES.drawings}`}
				className={
					'mx-4 text-center rounded-theme-rounding bg-ssiGold1 dark:bg-ssiGold1 py-2 px-4 text-ssiDark2 font-semibold dark:text-ssiDark4 hover:opacity-80 hover:-translate-y-0.5'
				}
			>
				DRAWINGS
			</Link>
			<Link
				to={`${ROUTES_PAGES.admin + ROUTES_PAGES.iot}`}
				className={
					'mx-4 text-center rounded-theme-rounding bg-ssiGold1 dark:bg-ssiGold1 py-2 px-4 text-ssiDark2 font-semibold dark:text-ssiDark4 hover:opacity-80 hover:-translate-y-0.5'
				}
			>
				IoT
			</Link>
		</React.Fragment>
	)
}

export default AdminLinks
