//design-view-links.js
import React from 'react'
import { ROUTES_PAGES } from '../../../constants'
import { Link } from 'react-router-dom'

const DesignViewLinks = () => {
	return (
		<React.Fragment>
			<Link
				to={`${ROUTES_PAGES.designViews}`}
				className={
					'mx-4 text-center rounded-theme-rounding bg-ssiGold1 dark:bg-ssiGold1 py-2 px-4 text-ssiDark2 font-semibold dark:text-ssiDark4 hover:opacity-80 hover:-translate-y-0.5'
				}
			>
				HOME
			</Link>
		</React.Fragment>
	)
}

export default DesignViewLinks
