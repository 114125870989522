import axios from 'axios'
import { API_CONST } from '../../constants/api'
import { REDUX_CONST } from '../constants/index.js'

export const resetItems = (key) => async (dispatch) => {
	switch (key) {
		case 'create':
			dispatch({ type: REDUX_CONST.autodeskConstants.uploadDrawingToHub.reset })

			break
		case 'delete':
			dispatch({ type: REDUX_CONST.autodeskConstants.deleteHubDrawing.reset })

			break
		default:
			break
	}
}

export const uploadDrawingToHub = (file) => async (dispatch, getState) => {
	// console.log('file')
	try {
		dispatch({
			type: REDUX_CONST.autodeskConstants.uploadDrawingToHub.request,
		})

		const {
			userLogin: { userInfo },
		} = getState()

		const formData = new FormData()
		formData.append('drawing', file)

		const config = {
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const response = await axios.post(
			API_CONST.channels.autodesk + API_CONST.routes.uploadDrawingToHub,
			formData,
			config,
		)
		// console.log(response.data)
		dispatch({
			type: REDUX_CONST.autodeskConstants.uploadDrawingToHub.success,
			payload: response.data,
		})
	} catch (error) {
		console.log(error)
		dispatch({
			type: REDUX_CONST.autodeskConstants.uploadDrawingToHub.fail,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		})
	}
}
export const fetchModel =
	({ modelUrn }) =>
	async (dispatch, getState) => {
		// console.log('Fetching model with URN:', modelUrn)
		try {
			dispatch({
				type: REDUX_CONST.autodeskConstants.fetchModel.request,
			})

			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					Authorization: `Bearer ${userInfo.token}`,
					'Content-Type': 'application/json', // Ensure this header is set for JSON body parsing on the server
				},
			}

			// Sending modelUrn in the request body
			const body = JSON.stringify({ urn: modelUrn })
			//console.log(body)
			const { data } = await axios.post(
				API_CONST.channels.autodesk + API_CONST.routes.fetchModel,
				body,
				config,
			)

			// console.log('Fetched data: ', data)

			dispatch({
				type: REDUX_CONST.autodeskConstants.fetchModel.success,
				payload: data,
			})
		} catch (error) {
			console.log(error)
			dispatch({
				type: REDUX_CONST.autodeskConstants.fetchModel.fail,
				payload:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}
export const listModels = () => async (dispatch, getState) => {
	// console.log('listModels')
	try {
		dispatch({
			type: REDUX_CONST.autodeskConstants.listModels.request,
		})

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(
			API_CONST.channels.autodesk + API_CONST.routes.listModels,
			config,
		)

		// console.log(data)

		dispatch({
			type: REDUX_CONST.autodeskConstants.listModels.success,
			payload: data,
		})
	} catch (error) {
		console.log(error)
		dispatch({
			type: REDUX_CONST.autodeskConstants.listModels.fail,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		})
	}
}
export const deleteHubDrawing =
	(bucketKey, objectName) => async (dispatch, getState) => {
		// console.log('deleteHubDrawing')

		try {
			dispatch({
				type: REDUX_CONST.autodeskConstants.deleteHubDrawing.request,
			})

			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					Authorization: `Bearer ${userInfo.token}`,
				},
			}

			const route = `${
				API_CONST.channels.autodesk
			}/buckets/${bucketKey}/objects/${encodeURIComponent(objectName)}`

			// console.log(route)

			const response = await axios.delete(route, config)

			// console.log(response.data)

			dispatch({
				type: REDUX_CONST.autodeskConstants.deleteHubDrawing.success,
				payload: response.data,
			})
		} catch (error) {
			console.log(error)
			dispatch({
				type: REDUX_CONST.autodeskConstants.deleteHubDrawing.fail,
				payload:
					error.response && error.response.data.message
						? error.response.data.message
						: error.message,
			})
		}
	}
