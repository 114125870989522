// BACKEND API ROUTES (EXPRESS AND ELECTRON)

//MAIN CHANNEL  FOR ELECTRON
export const API_COMMUNICATIONS = 'API_COMMUNICATIONS'
export const MAIN_COMMUNICATIONS = 'MAIN_COMMUNICATIONS'

export const API_CONST = {
	channels: {
		iot: '/api/iot',
		users: '/api/users',
		autodesk: '/api/autodesk',
	},
	routes: {
		controlLight: '/control-light',
		uploadDrawingToHub: '/upload-drawing-to-hub',
		deleteHubDrawing: '/buckets/:bucketKey/objects/:objectName',
		listModels: '/list-models',
		fetchModel: '/fetch-model',
		home: '/',
		users: {
			login: '/login1',
			profile: '/profile',
		},
	},
}
