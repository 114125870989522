//user-add-edit.js
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Loader } from '../../../components'

import { useStateContext } from '../../../contexts/ContextProvider'
import { CONST_ADD } from '../../../constants/general'
import { getUsers } from '../../../redux/actions'
import { resetUserItem } from '../../../redux/actions/userActions/userActions'

const UserAddEdit = ({ onSave, type, user }) => {
	const { handleClick, initialState } = useStateContext()

	const dispatch = useDispatch()

	const createUserRdx = useSelector((state) => state.createUser)
	const { loading: createLoading, data: createSuccess } = createUserRdx
	const updateUserRdx = useSelector((state) => state.updateUser)
	const { loading: updateLoading, data: updateSuccess } = updateUserRdx

	const [loading, setLoading] = useState(false)

	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')

	const [isAdmin, setIsAdmin] = useState(false)
	const [isEmployee, setIsEmployee] = useState(false)
	const [isExecutive, setIsExecutive] = useState(false)

	const changeEmailHandler = (e) => setEmail(e.target.value)
	const changeNameHandler = (e) => setName(e.target.value)
	const changePasswordHandler = (e) => setPassword(e.target.value)

	const changeIsAdminHandler = (e) => setIsAdmin(e.target.checked)
	const changeIsEmployeeHandler = (e) => setIsEmployee(e.target.checked)
	const changeIsExecutiveHandler = (e) => setIsExecutive(e.target.checked)

	useEffect(() => {
		if (createLoading || updateLoading) {
			setLoading(true)
		} else {
			setLoading(false)
		}

		return () => {}
	}, [createLoading, updateLoading])

	useEffect(() => {
		if (createSuccess || updateSuccess) {
			handleClick({ ...initialState })
			createSuccess && dispatch(resetUserItem('createUser'))
			updateSuccess && dispatch(resetUserItem('updateUser'))
			dispatch(getUsers())
		}

		return () => {}
	}, [createSuccess, updateSuccess])

	useEffect(() => {
		if (user !== undefined) {
			setEmail(user.email)
			setName(user.name)
			setIsAdmin(user.isAdmin)
			setIsEmployee(user.isEmployee)
			setIsExecutive(user.isExecutive)
			setPassword('')
		} else {
			setEmail('')
			setName('')
			setPassword('')
			setIsAdmin(false)
			setIsExecutive(false)
			setIsEmployee(false)
		}
		return () => {}
	}, [type, user])

	return (
		<form
			autoComplete='off'
			onSubmit={(e) =>
				onSave(
					e,
					{ ...user, name, password, email, isAdmin, isEmployee, isExecutive },
					type,
				)
			}
		>
			<div className='flex flex-col space-y-4 my-4 mx-6'>
				<input
					type='text'
					name='add_username_no_autocomplete'
					value={name}
					onChange={changeNameHandler}
					placeholder='Enter Username'
					// className=' pl-3 border-0 text-gray-600 text-sm  py-2 w-full border-b-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-yellow-500'
					className='w-full px-6 py-4 focus:border-b-2 border-b-1 border-ssiLightBorder1 dark:border-ssiDarkBorder1 focus:border-ssiGold1 focus:dark:border-ssiGold1 bg-ssiLight1  focus:outline-none   dark:text-ssiDarkTextActive  dark:bg-ssiDark4 placeholder:font-sans placeholder:font-light'
				/>
				<input
					type='email'
					name='addUserName_no_autocomplete'
					value={email}
					onChange={changeEmailHandler}
					placeholder='Enter email address'
					// className=' pl-3 border-0 text-gray-600 text-sm  py-2 w-full border-b-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-yellow-500'
					className='w-full px-6 py-4 focus:border-b-2 border-b-1 border-ssiLightBorder1 dark:border-ssiDarkBorder1 focus:border-ssiGold1 focus:dark:border-ssiGold1 bg-ssiLight1  focus:outline-none   dark:text-ssiDarkTextActive  dark:bg-ssiDark4 placeholder:font-sans placeholder:font-light'
				/>

				<input
					type='password'
					name='addUserPassword'
					value={password}
					onChange={changePasswordHandler}
					placeholder='Enter password'
					// className=' pl-3 border-0 text-gray-600 text-sm  py-2 w-full border-b-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-yellow-500'
					className='w-full px-6 py-4 focus:border-b-2 border-b-1 border-ssiLightBorder1 dark:border-ssiDarkBorder1 focus:border-ssiGold1 focus:dark:border-ssiGold1 bg-ssiLight1  focus:outline-none   dark:text-ssiDarkTextActive  dark:bg-ssiDark4 placeholder:font-sans placeholder:font-light'
				/>

				<div className='flex items-center space-x-2 my-2'>
					<input
						type='checkbox'
						checked={isAdmin}
						onChange={changeIsAdminHandler}
					/>
					<label>Is Admin?</label>
				</div>
				<div className='flex items-center space-x-2 my-2'>
					<input
						type='checkbox'
						checked={isEmployee}
						onChange={changeIsEmployeeHandler}
					/>
					<label>Is Employee?</label>
				</div>
				<div className='flex items-center space-x-2 my-2'>
					<input
						type='checkbox'
						checked={isExecutive}
						onChange={changeIsExecutiveHandler}
					/>
					<label>Is Executive?</label>
				</div>
			</div>
			<footer className='flex justify-end p-3 mt-4 border-t-1 border-ssiLightBorder1 dark:border-ssiDarkBorder1'>
				<button
					type='submit'
					className={` px-6 py-2 w-36 rounded bg-ssiGold1 text-ssiLight2 dark:bg-ssiGold1 dark:text-ssiDark3 `}
				>
					{loading ? <Loader className={' mx-auto '} /> : `Save`}
				</button>
			</footer>
		</form>
	)
}

UserAddEdit.defaultProps = {
	type: CONST_ADD,
}

export default UserAddEdit
