import { REDUX_CONST } from '../constants'

export const controlLight = (state = {}, action) => {
	switch (action.type) {
		case REDUX_CONST.iotConstants.controlLight.request:
			return { loading: true }
		case REDUX_CONST.iotConstants.controlLight.success:
			return { data: action.payload }
		case REDUX_CONST.iotConstants.controlLight.fail:
			return { error: action.payload }
		case REDUX_CONST.iotConstants.controlLight.reset:
			return {}
		default:
			return state
	}
}
