export const autodeskConstants = {
	listModels: {
		request: 'MODELS_LIST_REQUEST',
		success: 'MODELS_LIST_SUCCESS',
		fail: 'MODELS_LIST_FAIL',
		reset: 'MODELS_LIST_RESET',
	},
	fetchModel: {
		request: 'MODEL_FETCH_REQUEST',
		success: 'MODEL_FETCH_SUCCESS',
		fail: 'MODEL_FETCH_FAIL',
	},

	uploadDrawingToHub: {
		request: 'UPLOAD_DRAWING_TO_HUB_REQUEST',
		success: 'UPLOAD_DRAWING_TO_HUB_SUCCESS',
		fail: 'UPLOAD_DRAWING_TO_HUB_FAIL',
		reset: 'UPLOAD_DRAWING_TO_HUB_RESET',
	},
	deleteHubDrawing: {
		request: 'DELETE_DRAWING_FROM_HUB_REQUEST',
		success: 'DELETE_DRAWING_FROM_HUB_SUCCESS',
		fail: 'DELETE_DRAWING_FROM_HUB_FAIL',
		reset: 'DELETE_DRAWING_FROM_HUB_RESET',
	},
}
