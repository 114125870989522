//IoTPage.js
import React from 'react'
import { AdminLinks, PageWrapper } from '../../../components'
import LightControl from './light-control'

const IoTPage = () => {
	return (
		<PageWrapper sidebarTitle='IoT Links' sidebarLinks={<AdminLinks />}>
			<LightControl />
		</PageWrapper>
	)
}

export default IoTPage
