//store.js
import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import {
	fetchModel,
	listModels,
	uploadDrawingToHub,
	deleteHubDrawing,
} from './reducers/autodeskReducers'
import { controlLight } from './reducers/iotReducers'

import {
	loginUser,
	createUser,
	getUserDetails,
	updateUserProfile,
	getUsers,
	deleteUser,
	updateUser,
} from './reducers/index'

const reducer = combineReducers({
	controlLight,
	deleteHubDrawing,
	uploadDrawingToHub,
	fetchModel,
	listModels,
	userLogin: loginUser,
	createUser,
	userDetails: getUserDetails,
	updateUserProfile,
	users: getUsers,
	deleteUser,
	updateUser,
})

const userInfoFromStorage = localStorage.getItem('userInfo')
	? JSON.parse(localStorage.getItem('userInfo'))
	: null

const initialState = {
	userLogin: { userInfo: userInfoFromStorage },
}

const middleware = [thunk]

const store = createStore(
	reducer,
	initialState,
	composeWithDevTools(applyMiddleware(...middleware)),
)

export default store
